var generic = generic || {};
Drupal.behaviors.setLineShopping = {
  attach: function() {
    var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split('&');
      var sParameterName, i;
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
          generic.cookie(sParam, sParameterName[1], { path: '/', expires: 1 });
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
      }
    };
    var line_shopidentifier = getUrlParameter('utm_source');
    var line_ecid = getUrlParameter('ecid');

    if (line_ecid && line_shopidentifier && line_shopidentifier.toLowerCase() === 'line') {
      var current_date = new Date();
      var utc = current_date.getTime() + (current_date.getTimezoneOffset() * 60000);
      var gmt_kr = 9;
      var time_offset = new Date(utc + (3600000 * gmt_kr));
      var locale_date = time_offset.toLocaleDateString('en-US');
      var locale_time = time_offset.toLocaleTimeString('en-US', { hour12: false });
      var redirect_time = escape(locale_date + ' ' + locale_time);
      if (redirect_time) {
        generic.cookie('lineShopRedirectTime', redirect_time, { path: '/', expires: 1 });
      }
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/shared/lineshop.tmpl?utm_source=' + line_shopidentifier + '&ecid=' + line_ecid;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }
};
